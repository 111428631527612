/* 
*
* variables
* */

:root {
  /* preferred main colors */
  --clr-primary: #fadc80;
  --clr-secondary: #6558f5;
  --clr-tertiary: #1aae9f;

  /* preferred main colors - lighter shades */
  /* --clr-primary-lighter: hsl(var(--clr-primary), 88%,70%); */
  --clr-primary-lighter: #fcf1c9;
  --clr-secondary-lighter: hsl(var(--clr-secondary), 88%, 70%);
  --clr-tertiary-lighter: hsl(var(--clr-tertiary), 88%, 70%);

  /* preferred main colors - darker shades */
  --clr-primary-darker: #f4b81d;

  /*  other colors used*/
  --clr-supporting-1: white;
}

/*
*
* Global styles
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: auto;
  padding: auto;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'sans-serif';
  background: var(--clr-primary);
  line-height: 1.5;
  font-size: 0.875rem;
  /* shouldnt this be a single digit (e.g 7) val? */
  font-weight: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

header {
  text-align: center;
  vertical-align: middle;
}

input,
label,
select {
  display: block;
  text-align: center;
  min-width: 15em;
  min-height: 2em;
}

label {
  text-align: center;
}

input {
  border: 1px solid black;
  width: 70%;
}

button,
button:active,
button:focus,
button:link,
button::after,
button:visited {
  width: 50%;
  height: 5rem;
  background-color: var(--clr-secondary);
  font-family: sans-serif;
  font-weight: 3rem;
  font-size: 2rem;
  color: white;
  align-self: center;
  display: flex;
}

/* TODO make this button special - custom class/id? */
button > a {
  color: white;
  text-decoration: none;
}

section {
  display: inline-flex;
}

/* 
*
* HOME
*
* */

/* TODO make this a specific id/class ? */
div > h1 {
  border: 5px solid var(--clr-tertiary);
  margin: 15%;
  margin-bottom: 4%;
  padding: 15%;
  text-align: center;
}

/* global classes */
.emission-input-form {
  margin: 5% 5% 5%;
  border: 5px solid var(--clr-tertiary);
}

/* select.car-form {
  border: 1em solid black;
  width: 10em;
}
*/
button[type='submit'] {
  display: inline-flex;
  fill: purple;
  /* border: 1em solid red; */
  /* width: 10em; */
  /* border:5px solid red; */
  /* align-self:center; */
}

.forms-container {
  margin: 2%;
  border: 5px solid var(--clr-tertiary);
  /* border-style: inset; */
}

.form-header {
  font-size: 1.5rem;
  font-weight: 9;
}

.emission-input-form {
  background: var(--clr-primary-lighter);
  border: 5px solid var(--clr-primary-darker);
  text-align: left;
  padding: 9px;
}

header.page-header {
  background: var(--clr-tertiary);
  filter: brightness(145%) grayscale(33%) sepia(39%);
  border: solid black 1px;
  text-align: center;
  vertical-align: middle;
}
